import {Link} from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

const SiteIndex = ({pageContext: {quizzes}}: any) => {
  return (
    <Layout>
      <div className="bg-white rounded-xl p-10">
        <ol style={{listStyle: 'none'}}>
          {quizzes.map(quiz => {
            return (
              <li key={quiz.slug}>
                <article
                  className="post-list-item text-center"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link to={`/${quiz.slug}/`} itemProp="url">
                        <span itemProp="headline">{quiz.title} Quiz</span>
                      </Link>
                    </h2>
                  </header>
                </article>
              </li>
            );
          })}
        </ol>
      </div>
    </Layout>
  );
};

export default SiteIndex;

export const Head = () => (
  <Seo
    title="Laquiz.tilyupo.com: Your Gateway to a World of Quizzes!"
    description="Unlock a world of knowledge with our diverse range of quizzes! Whether you're an expert in a particular field or just curious to learn something new, we have a quiz tailored just for you. Challenge yourself with our carefully crafted questions and see how well you know your chosen subject. Ready to put your knowledge to the test? Explore our quizzes now!"
    type="website"
  />
);
